import GameStats from '../types/gameplay/GameStats'
import GameBars from '../types/gameplay/GameBars'

export const GREEN_THRESHOLD = 100
export const YELLOW_THRESHOLD = 300
export const MAX_SCORE = 1100
/**
 * The number of previous games to display.
 */
export const NUM_PREV_DAYS = 5

/**
 * The maximum width (in characters) that
 * the stats bars can occupy.
 */
export const MAX_BAR_LENGTH = 10

/**
 * Cosine similarity (the API) produces scores
 * between -1 and 1. Normalize this range to a
 * number between MAX_SCORE to 0.
 */
export function normalizeScore(score: number) {
  if (score === 0) {
    return 0
  }
  return Math.round(MAX_SCORE - (((score + 1) / 2) * MAX_SCORE))
}

/**
 * Map a normalized score (MAX_SCORE to 0) to
 * a display color.
 *
 * @param score
 */
export function getColor(score: number): 'green' | 'yellow' | 'red' {
  if (score < GREEN_THRESHOLD) {
    return 'green'
  }

  if (score < YELLOW_THRESHOLD) {
    return 'yellow'
  }

  return 'red'
}

export function getBars(stats: GameStats): GameBars {
  const renderBar = (count: number, char: string) => char.repeat(count)

  let redCount
  let yellowCount
  let greenCount

  if ([stats.red, stats.yellow, stats.green].some(s => s >= MAX_BAR_LENGTH)) {
    const total = stats.red + stats.yellow + stats.green
    redCount = Math.round(stats.red / total * MAX_BAR_LENGTH)
    yellowCount = Math.round(stats.yellow / total * MAX_BAR_LENGTH)
    greenCount = Math.round(stats.green / total * MAX_BAR_LENGTH)
  } else {
    redCount = stats.red
    yellowCount = stats.yellow
    greenCount = stats.green
  }

  return {
    red: renderBar(Math.max(redCount, 1), '🟥'),
    yellow: renderBar(Math.max(yellowCount, 1), '🟨'),
    green: renderBar(Math.max(greenCount, 1), '🟩'),
  }
}
