<template>
  <div class="guess bg-zinc-50 rounded-md h-10 relative shadow-sm">
    <div class="guess__fill-bounds h-10 absolute z-0 top-0 left-0" :style="`width: ${pctFill * 100}%;`">
      <div class="guess__fill h-10 bg-zinc-100 absolute z-0 top-0 left-0 rounded-md"
        :style="`background-color: ${colorFill};`" />
    </div>
    <div class="guess__text z-10 flex justify-between h-10 items-center relative p-2">
      <div class="guess__text-guess">
        {{ props.guess }}
      </div>
      <div class="guess__text-score">
        <span v-if="props.score > 0" class="font-semibold">
          <!-- {{ props.score }} -->
        </span>
        <span v-else>
          🏆
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, onMounted, ref, watch } from 'vue'

import { getColor } from '../lib/gameplay'

import ScoredGuess from '../types/ScoredGuess'

const MIN_FILL = 0.05
const MAX_SCORE = 600

const props = defineProps<ScoredGuess>()
const score = ref(1000)

const pctFill = computed(() => {
  return Math.max((MAX_SCORE - score.value) / MAX_SCORE, MIN_FILL)
})

onMounted(() => {
  // Force the score to incur a CSS transition
  // after the initial render (this animates the fill)
  setTimeout(() => {
    score.value = props.score
  }, 1)
})

watch(() => props.score, (newScore) => {
  score.value = newScore
})

const colorFill = computed(() => {
  const color = getColor(props.score)

  switch (color) {
    case 'green':
      return '#86efac'
    case 'yellow':
      return '#fde68a'
    case 'red':
      return '#fca5a5'
  }
})
</script>

<style scoped lang="scss">
.guess__fill-bounds {
  width: 0%;
  transition: all 1.2s ease-in;
}

.guess__fill {
  width: 100%;
}
</style
