<template>
  <div
    class="bottom-0 left-0 right-0 p-4 text-zinc-400">
    <div class="flex justify-center items-center gap-3 text-xs">
      <a
        href="#"
        class=""
        @click="showBadge = true">
        <span
          class="font-medium">Special Agent:</span>
        {{ agent.name }} {{ agent.emoji }}
      </a>
    </div>
  </div>
  <div v-show="showBadge" class="fixed inset-0 p-3 bg-zinc-900 bg-opacity-50 backdrop-blur-sm z-50 flex items-center">
    <div class="bg-zinc-50 shadow-sm rounded-md p-4 sm:w-1/2 md:w-80 lg:w-80 mx-auto">
      <div class="text-md font-semibold mb-2">
        Special Agent Badge
      </div>
      <div class="border-b-2 border-zinc-200 my-2"></div>
      <div class="flex justify-left items-center gap-3 text-sm">
        <div class="rounded-full bg-zinc-200 w-10 h-10 flex justify-center items-center">
          <span class="text-zinc-900 text-2xl font-bold">
            {{ agent.emoji }}
          </span>
        </div>
        <div>
          <div>
            <span class="font-medium">Special Agent:</span>
            {{ agent.name }}
          </div>
          <div>
            <span class="font-medium">Streak: </span>
            {{ streak }}
            <span
              v-if="streak > 3"
              class="text-xs text-zinc-800 font-medium">
              🔥
            </span>
          </div>
          <div class="text-zinc-500">
            <span class="font-medium">Iss:</span>
            {{ issDate }}
          </div>
          <div class="text-zinc-500">
            <span class="font-medium">Exp:</span>
            {{ expDate }}
          </div>
        </div>
      </div>
      <!-- Divider -->
      <div class="border-b-2 border-zinc-200 my-2"></div>
      <div class="flex justify-between items-center py-2">
        <div class="text-xs text-zinc-500">
          <span class="font-medium">ID: </span>
          <a
            @click="changeAgent"
            href="#"
            class="underline">{{ agent.id }}</a>
        </div>
        <a @click="showBadge = false" class="text-sm text-zinc-900 underline font-semibold" href="#">
          Close
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useToast } from 'vue-toastification'

import Agent from '../../types/Agent'
import { getCurrentStreak, setAgent } from '../../lib/state'

defineProps<{
  agent: Agent
}>()

const toast = useToast()

const showBadge = ref<boolean>(false)
const streak = ref<number>(getCurrentStreak())
const issDate = ref<string>('2024-03-01')
const expDate = ref<string>('2026-03-01')

function changeAgent() {
  const id = prompt('Enter a new Agent ID:')

  if (!id) {
    toast.info('Your Agent ID was not changed.')
    return
  }

  try {
    setAgent(parseInt(id, 10))
  } catch (err) {
    if (err instanceof Error) {
      toast.error(err.message)
      return
    }
  }
  toast.success('Updating your Agent ID...')
  setTimeout(() => {
    window.location.reload()
  }, 1500)
}

</script>
