<template>
  <div style="height: 75px;"
    class="play__heading-area fixed w-full top-0 left-0 bg-zinc-50/50 backdrop-blur-sm z-50 shadow-sm">
    <div class="text-zinc-900 container mx-auto p-4 flex justify-between items-center h-full">
      <div class="text-2xl instrument-serif-regular text-zinc-900">
        <router-link to="/" class="">
          🕵️
          Riddlemoji
        </router-link>
      </div>
      <div class="text-sm">
        <span class="font-semibold">#{{ gameNumber }}</span>
        /
        <select :value="modelValue" @change="updateSelectedDate" class="font-semibold bg-zinc-50 border border-zinc-300 rounded-md p-1">
          <option v-for="date in pastGameDates" :value="date.value" :key="date.value">{{ date.display }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineModel } from 'vue'
import { getDefaultGameDate } from '../../lib/api'
import { getSavedGameState } from '../../lib/state'
import { NUM_PREV_DAYS } from '../../lib/gameplay'
import GameplayState from '../../types/GameplayState'

const props = defineProps<{
  gameNumber: string
  gameplayState: GameplayState
}>()

const model = defineModel({ type: String })

const pastGameDates = computed(() => {
  // Force reactivity on gameplayState
  props.gameplayState;
  const today = getDefaultGameDate()
  const dates: { display: string, value: string }[] = []
  for (let i = 0; i < NUM_PREV_DAYS; i++) {
    const date = new Date(today)
    const offsetDate = new Date(date.setDate(date.getDate() - i))
    const offsetDateStr = offsetDate.toISOString().split('T')[0]
    let offsetDateStrFormatted = offsetDateStr

    const savedState = getSavedGameState(offsetDateStr)
    // This allows us to be reactive to the current game,
    // whose changes are asynchronously written to the saved state
    const gameplayState = offsetDateStr === model.value
      ? props.gameplayState
      : savedState?.state

    if (gameplayState) {
      if (gameplayState === GameplayState.Solved) {
        offsetDateStrFormatted = `${offsetDateStr} 🏆`
      } else if (gameplayState === GameplayState.Playing) {
        offsetDateStrFormatted = `${offsetDateStr} 🔍`
      } else if (gameplayState === GameplayState.GaveUp) {
        offsetDateStrFormatted = `${offsetDateStr} 🤷`
      }
    } else {
      offsetDateStrFormatted = `${offsetDateStr} ✨`
    }
    dates.push({ display: offsetDateStrFormatted, value: offsetDateStr })
  }
  return dates
})

function updateSelectedDate(event: Event) {
  model.value = (event.target as HTMLSelectElement).value
}
</script>
