<template>
  <div class="onboarding__backdrop fixed inset-0 p-3 bg-zinc-900 bg-opacity-50 backdrop-blur-sm z-50 flex items-center">
    <div class="onboarding bg-zinc-50 shadow-sm rounded-md p-3 md:w-1/2 lg:w-1/3 mx-auto">
      <div class="onboarding__heading text-lg mb-2">
        How to Play
      </div>
      <div class="onboarding__example border-zinc-300 border-2 border-dashed rounded-md p-3 mb-2">
        <div class="onboarding__example-message py-2 mb-3">
          <div v-if="message" class="text-sm w-full text-center bg-sky-200 rounded-md p-2 border-2 border-sky-300">
            <Transition mode="out-in">
              <span :key="message">
                {{ message }}
              </span>
            </Transition>
          </div>
        </div>
        <div :class="{ blur: !showRiddle }" class="onboarding__example-riddle blur-target text-center mb-2">
          <span class="font-semibold">Riddle:</span>
          {{ riddle }}
        </div>
        <div class="onboarding__example-emoji blur-target" :class="{ blur: !showEmoji }">
          <EmojiList :items="emoji" class="mb-3" />
        </div>
        <div class="onboarding__example-guess-area blur-target" :class="{ blur: !showGuessArea }">
          <input v-model="guess" type="text" class="w-full rounded-md p-2 border-2 border-zinc-300" placeholder="Guess"
            disabled />
          <div class="py-2">
            <Guess v-for="guess in guesses.slice().reverse()" class="mb-2" :key="guess.guess" :guess="guess.guess"
              :score="guess.score" />
          </div>
        </div>
      </div>

      <div class="flex justify-between py-2 items-center">
        <div class="text-sm font-semibold">
          Good luck!
        </div>
        <div class="flex gap-3 justify-end items-center">
          <a v-if="!isPlaying" @click="play" class="text-sm text-zinc-900 underline font-semibold" href="#">
            Replay
          </a>
          <a @click="$emit('dismiss')" href="#"
            class="text-sm bg-zinc-900 text-zinc-50 font-semibold py-2 px-3 rounded-full text-center">Close Tutorial</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

import Guess from '../components/Guess.vue'
import EmojiList from './EmojiList.vue'
import ScoredGuess from '../types/ScoredGuess'
import { trackEvent } from '../lib/metrics'

const emoji = ref<string[]>([])
const guess = ref('')
const guesses = ref<ScoredGuess[]>([])
const message = ref<string>('')
const riddle = `"Don't talk to strangers" and other cautionary tales`
const isPlaying = ref(true)
const showRiddle = ref(false)
const showGuessArea = ref(false)
const showEmoji = ref(false)

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

function reset() {
  emoji.value = ['🌳', '🍎']
  guesses.value = []
  message.value = ''
  guess.value = ''
  showRiddle.value = false
  showGuessArea.value = false
  showEmoji.value = false
}

async function animateInput(input: string) {
  for (let i = 0; i < input.length; i++) {
    guess.value = input.slice(0, i + 1)
    await sleep(75)
  }
}

async function play() {
  reset()
  await sleep(1000) // Wait for animations
  isPlaying.value = true
  message.value = 'Tutorial starts in 3...'
  await sleep(1500)
  message.value = 'Tutorial starts in 2...'
  await sleep(1500)
  message.value = 'Tutorial starts in 1...'
  await sleep(1500)
  message.value = 'Welcome to Riddlemoji!'
  await sleep(3000)
  message.value = 'Every day, you get a new riddle...'
  await sleep(3000)
  showRiddle.value = true
  await sleep(3000)
  message.value = '...and a few emoji.'
  await sleep(2000)
  showEmoji.value = true
  await sleep(3000)
  message.value = 'Your goal is to guess the riddle using the emoji as a hint.'
  await sleep(3000)
  showGuessArea.value = true
  await sleep(3000)
  message.value = "We'll make our first guess. Maybe it's the tale of Snow White?"
  await sleep(3500)
  await animateInput('snow white')
  await sleep(2000)
  guess.value = ''
  guesses.value.push({
    guess: 'snow white',
    score: 552,
  })
  await sleep(3000)
  message.value = "Hmm...the first guess wasn't very close. We'll get a new emoji as a hint."
  await sleep(3000)
  emoji.value.push('🚫')
  await sleep(4000)
  message.value = "Let's try again."
  await sleep(1000)
  await animateInput("the giving tree")
  await sleep(2000)
  guess.value = ''
  guesses.value.push({
    guess: "the giving tree",
    score: 285,
  })
  await sleep(2000)
  message.value = "The second guess is a bit closer, but it's not quite there. Let's try again."
  await sleep(3000)
  emoji.value.push('🐍')
  await sleep(4000)
  await animateInput('adam and eve')
  await sleep(1000)
  guess.value = ''
  guesses.value.push({
    guess: 'adam and eve',
    score: 0,
  })
  await sleep(1000)
  message.value = "Solved! You're ready for your own riddle."
  await sleep(2000)
  isPlaying.value = false
  trackEvent('onboarding.complete')
}

onMounted(async () => {
  trackEvent('onboarding.start')
  play()
})

</script>

<style scoped>
.blur-target {
  transition: filter 0.8s !important;
}

.blur {
  filter: blur(5px) !important;
}

.onboarding__example-emoji {
  filter: blur(5px);
  transform: scale(0.9);
  animation: focusIn 1s forwards;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
