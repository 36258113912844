import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import Toast, { POSITION, PluginOptions as ToastOptions } from 'vue-toastification'

import './style.css'
import './assets/styles/index.scss'

import router from './router'
import App from './App.vue'

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://89f95852a6c7077833f18ca0b268566c@o4506939984379904.ingest.us.sentry.io/4506939993423872',
  environment: import.meta.env.PROD ? 'production' : 'development',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/api.riddlemoji\.com\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.use(router)
app.use(Toast, {
  position: POSITION.TOP_CENTER,
  hideProgressBar: true,
  closeButton: false,
  icon: false,
  containerClassName: 'sticky',
  transition: 'Vue-Toastification__slideBlurred',
  timeout: 2500,
} as ToastOptions)
app.mount('#app')
