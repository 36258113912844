<template>
  <div class="confirm-link inline-block">
    <a v-if="currentState !== ConfirmState.Confirmed" @click="handleClick" href="#">
      {{ currentText }}
    </a>
    <span v-else>
      {{ currentText }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'

enum ConfirmState {
  Default,
  Confirming,
  Confirmed,
}

const emit = defineEmits(['confirm'])

const props = defineProps<{
  defaultText: string
  confirmText: string
  confirmedText: string
}>()

const currentState = ref<ConfirmState>(ConfirmState.Default)

const currentText = computed(() => {
  switch (currentState.value) {
    case ConfirmState.Default:
      return props.defaultText
    case ConfirmState.Confirming:
      return props.confirmText
    case ConfirmState.Confirmed:
      return props.confirmedText
  }
})

function handleClick() {
  switch (currentState.value) {
    case ConfirmState.Default:
      currentState.value = ConfirmState.Confirming
      setTimeout(() => {
        if (currentState.value === ConfirmState.Confirming) {
          currentState.value = ConfirmState.Default
        }
      }, 3000)
      break
    case ConfirmState.Confirming:
      currentState.value = ConfirmState.Confirmed
      emit('confirm')
      break
    case ConfirmState.Confirmed:
      break
  }
}
</script>
