import cookie from 'js-cookie'
import Agent from '../types/Agent'
import GameplayState from '../types/GameplayState'
import LostStreak from '../types/LostStreak'
import SavedGameState from '../types/SavedGameState'
import ScoredGuess from '../types/ScoredGuess'
import StreakState from '../types/StreakState'
import { generateNewAgent, getAgent } from './agent'
import { getDefaultGameDate, getYesterdayGameDate } from './api'
import { NUM_PREV_DAYS } from './gameplay'
import { captureBreadcrumb, captureException, trackEvent } from './metrics'

const NAMESPACE = 'riddlemoji'
/**
 * If this is incremented, previous game states will be invalidated.
 * Do this when the game state format changes.
 */
const GAME_STATE_VERSION = 1

/**
 * If this is incremented, previous streak states will be invalidated.
 * Do this when the streak state format changes.
 */
const STREAK_STATE_VERSION = 1

function removeNPreviousGameStates(n: number) {
  try {
    const date = getDefaultGameDate()
    const dates: string[] = []
    for (let i = 0; i < n; i++) {
      const d = new Date(date)
      d.setDate(d.getDate() - i)
      dates.push(d.toISOString().split('T')[0])
    }
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(`${NAMESPACE}:gameState:`)) {
        const state = JSON.parse(localStorage.getItem(key) as string) as SavedGameState
        if (!dates.includes(state.date)) {
          localStorage.removeItem(key)
          console.debug(`Removed old game state for ${state.date}`)
          captureBreadcrumb({
            message: `Removed old game state for ${state.date}`,
            category: 'state',
            level: 'info',
          })
        }
        else if (state.version !== GAME_STATE_VERSION) {
          localStorage.removeItem(key)
          console.debug(`Removed game state for ${state.date} due to schema invalidation`)
          captureBreadcrumb({
            message: `Removed game state for ${state.date} due to schema invalidation`,
            category: 'state',
            level: 'info',
          })
        }
      }
    })
  } catch (err) {
    if (err instanceof Error) {
      captureException(err)
    }
  }
}

export function setSavedGameState(args: ({
  date: string,
  guesses: ScoredGuess[],
  state: GameplayState
})) {
  try {
    removeNPreviousGameStates(NUM_PREV_DAYS)
    const state: SavedGameState = {
      version: GAME_STATE_VERSION,
      ...args
    }
    console.log(`Saving game state: ${JSON.stringify(state)}`)
    localStorage.setItem(`${NAMESPACE}:gameState:${args.date}`, JSON.stringify(state))
  } catch (err) {
    if (err instanceof Error) {
      captureException(err)
    }
  }
}

export function getSavedGameState(date: string): SavedGameState | null {
  try {
    removeNPreviousGameStates(NUM_PREV_DAYS)
    const state = localStorage.getItem(`${NAMESPACE}:gameState:${date}`)
    if (state) {
      return JSON.parse(state) as SavedGameState
    }
    return null
  } catch (err) {
    if (err instanceof Error) {
      captureException(err)
    }
    return null
  }
}

export function getStreakState(): StreakState | null {
  try {
    const state = localStorage.getItem(`${NAMESPACE}:streakState`)
    if (state) {
      return JSON.parse(state) as StreakState
    }
    return null
  } catch (err) {
    if (err instanceof Error) {
      captureException(err)
    }
    return null
  }
}

export function getCurrentStreak(): number {
  const streakState = getStreakState()
  if (streakState) {
    return streakState.count
  }
  return 0
}

/**
 * @returns {number} The new streak count
 */
export function recordStreakWin(): number {
  const today = getDefaultGameDate()
  const yesterday = getYesterdayGameDate()
  const streakState = getStreakState()

  // Case 1: No existing state
  if (!streakState) {
    const newState: StreakState = {
      version: STREAK_STATE_VERSION,
      count: 1,
      lastWin: today
    }
    try {
      localStorage.setItem(`${NAMESPACE}:streakState`, JSON.stringify(newState))
      trackEvent('streak.update', { count: newState.count })
      return newState.count
    } catch (err) {
      if (err instanceof Error) {
        captureException(err)
      }
    }
    // If we can't save the state,
    // we won't ever be able to maintain streaks.
    return 0
  }

  // Case 2: Existing state, was yesterday
  if (streakState.lastWin === yesterday) {
    const newState: StreakState = {
      version: STREAK_STATE_VERSION,
      count: streakState.count + 1,
      lastWin: today
    }
    try {
      localStorage.setItem(`${NAMESPACE}:streakState`, JSON.stringify(newState))
      trackEvent('streak.update', { count: newState.count })
      return newState.count
    } catch (err) {
      if (err instanceof Error) {
        captureException(err)
      }
    }
    // If we can't save the state,
    // we won't ever be able to maintain streaks.
    return 0
  }

  // Case 3: Existing state, is today
  if (streakState.lastWin === today) {
    return streakState.count
  }

  // Case 4: Existing state, was not yesterday
  const newState: StreakState = {
    version: STREAK_STATE_VERSION,
    count: 1,
    lastWin: today
  }

  try {
    localStorage.setItem(`${NAMESPACE}:streakState`, JSON.stringify(newState))
    trackEvent('streak.update', { count: newState.count })
    return newState.count
  } catch (err) {
    if (err instanceof Error) {
      captureException(err)
    }
  }

  // If we can't save the state,
  // we won't ever be able to maintain streaks.
  return 0
}

/**
 * @returns {boolean} True if a streak was cleared
 */
export function clearStreak(): boolean {
  const streakState = getStreakState()

  if (!streakState) {
    return false
  }

  try {
    localStorage.removeItem(`${NAMESPACE}:streakState`)
    return true
  } catch (err) {
    if (err instanceof Error) {
      captureException(err)
    }
  }

  // If we can't save the state,
  // we won't ever be able to maintain streaks.
  return false
}

/**
 * Evaluate whether the player has lost their streak
 * by not playing yesterday.
 *
 * @returns {boolean} True if the player lost their streak
 */
export function checkAndClearLostStreak(): LostStreak {
  const today = getDefaultGameDate()
  const yesterday = getYesterdayGameDate()
  const streakState = getStreakState()

  // Case 1: No existing state
  if (!streakState) {
    return {
      didLose: false,
      previousCount: 0
    }
  }

  // Case 2: Existing state, was yesterday
  if (streakState.lastWin === yesterday) {
    return {
      didLose: false,
      previousCount: streakState.count
    }
  }

  // Case 3: Existing state, is today
  if (streakState.lastWin === today) {
    return {
      didLose: false,
      previousCount: streakState.count
    }
  }

  // Case 4: Existing state, was not yesterday
  clearStreak()
  trackEvent('streak.lost')
  return {
    didLose: true,
    previousCount: streakState.count
  }
}


export function getOrCreateAgent(): Agent {
  const savedAgentId = cookie.get('profile')
  if (savedAgentId) {
    cookie.set('profile', savedAgentId, { expires: 365 })
    const seed = parseInt(savedAgentId, 10)
    return getAgent(seed)
  }
  const newAgent = generateNewAgent()
  cookie.set('profile', newAgent.id.toString(), { expires: 365 })
  return newAgent
}

/**
 * Set the agent ID in a cookie.
 * You should reload the page after calling this function.
 * @param id The ID of the agent to set
 */
export function setAgent(id: number) {
  if (id.toString().length !== 9) {
    throw new Error('Invalid Agent ID')
  }
  cookie.set('profile', id.toString(), { expires: 365 })
}
