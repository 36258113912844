import Agent from '../types/Agent'

const adjectives = [
  'Brave', 'Cunning', 'Sly', 'Witty', 'Mysterious', 'Shadowy', 'Secretive', 'Stealthy', 'Crafty', 'Silent',
  'Quirky', 'Zany', 'Daring', 'Eccentric', 'Funky', 'Peculiar', 'Roguish', 'Spunky', 'Whimsical', 'Nimble'
]
const nouns = [
  'Detective', 'Sleuth', 'Investigator', 'Spy', 'Agent', 'Operative', 'Inspector', 'Tracker', 'Scout', 'Observer',
  'Hawk', 'Fox', 'Panther', 'Raven', 'Wolf', 'Falcon', 'Jaguar', 'Lynx', 'Viper', 'Eagle'
]
const emojis = ['🕵️‍♂️', '🕵️‍♀️', '🔍', '👀', '🔦', '📜', '🔑', '🗝️', '🛡️', '🔒']


function generateSeed(): number {
  return Math.floor(Math.random() * 1000000000)
}

export function generateNewAgent(): Agent {
  const seed = generateSeed()
  return getAgent(seed)
}

export function getAgent(seed: number): Agent {
  const adjective = adjectives[seed % adjectives.length]
  const noun = nouns[seed % nouns.length]
  const emoji = emojis[seed % emojis.length]

  return {
    id: seed,
    name: `${adjective} ${noun}`,
    emoji,
  }
}
