import Agent from '../types/Agent'
import TimeOfDay from '../types/TimeOfDay'
import { getOrCreateAgent } from './state'

type GreetingFunction = (agent: Agent) => string

const greetings: { [key: string]: Array<string | GreetingFunction> } = {
  [TimeOfDay.EarlyMorning]: [
    'Early bird gets the Riddlemoji!',
    "Couldn't sleep?",
    'Good morning sunshine!',
    "It's too early for this",
    "Riddlemoji > coffee",
    (agent: Agent) => `You need your coffee, ${agent.name}`,
  ],
  [TimeOfDay.Morning]: [
    'Good morning!',
    'Rise and shine!',
    'You came back <3',
    'Good to see you again!',
    'Hello again!',
    (agent: Agent) => `Good morning, ${agent.name}`,
    (agent: Agent) => `You're up early, ${agent.name}`,
  ],
  [TimeOfDay.Afternoon]: [
    'Good afternoon!',
    'Riddlemoji > lunch break',
    "Couldn't stay away, huh?",
    'Welcome back, Detective!',
    'Welcome back, friend!',
    "Let's play!",
    (agent: Agent) => `Good afternoon, ${agent.name}`,
    (agent: Agent) => `Remember your lunch, ${agent.name}`,
  ],
  [TimeOfDay.Evening]: [
    'Good evening, Detective!',
    'How was your day?',
    'Time to unwind with some Riddlemoji',
    'Welcome back!',
    'The detective returns!',
    (agent: Agent) => `Good evening, ${agent.name}`,
  ],
  [TimeOfDay.Night]: [
    'Good night!',
    'Sleep tight!',
    'Some bedtime Riddlemoji?',
    'Sweet dreams!',
    'Dream of Riddlemoji!',
    'Riddlemoji puts me to sleep too',
    (agent: Agent) => `Good night, ${agent.name}`,
    (agent: Agent) => `Time for bed, ${agent.name}`,
  ],
  [TimeOfDay.LateNight]: [
    'U up?',
    'Still up?',
    'Burning the midnight oil?',
    'Go to bed (you know who you are)',
    'Your bed misses you',
    'You should be sleeping',
    'Sleep is for the weak',
    "Couldn't sleep?",
    (agent: Agent) => `Go to bed, ${agent.name}`,
    (agent: Agent) => `You should be sleeping, ${agent.name}`,
  ],
}

export function getTimeOfDay(): TimeOfDay {
  const currentHour = new Date().getHours()
  if (currentHour >= 6 && currentHour < 8) {
    return TimeOfDay.EarlyMorning
  } else if (currentHour >= 8 && currentHour < 12) {
    return TimeOfDay.Morning
  } else if (currentHour >= 12 && currentHour < 18) {
    return TimeOfDay.Afternoon
  } else if (currentHour >= 18 && currentHour < 21) {
    return TimeOfDay.Evening
  } else if (currentHour >= 21 && currentHour < 23) {
    return TimeOfDay.Night
  } else {
    return TimeOfDay.LateNight
  }
}

export function getTimeOfDayGreeting(): string {
  const timeOfDay = getTimeOfDay()
  const greetingList = greetings[timeOfDay]
  const agent = getOrCreateAgent()
  const greeting = greetingList[Math.floor(Math.random() * greetingList.length)]
  if (typeof greeting === 'function') {
    return greeting(agent)
  }
  return greeting
}
