import axios, { AxiosRequestConfig } from 'axios'
import axiosRetry from 'axios-retry'
import { captureBreadcrumb } from './metrics'
import GameParams from '../types/gameplay/GameParams'

const v1 = axios.create({
  baseURL: import.meta.env.PROD
    ? 'https://api.riddlemoji.com/api/v1'
    : `${window.location.protocol}//${window.location.hostname}:3000/api/v1`,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosRetry(v1, { retries: 3, retryDelay: axiosRetry.exponentialDelay, onRetry(retryCount: number, _error: any, requestConfig: AxiosRequestConfig<any>) {
  captureBreadcrumb({
    message: `Retrying request ${requestConfig.url} (retry num: ${retryCount})`,
    category: 'network',
    level: 'info',
  })
}})

export function getDefaultGameDate() {
  const currentDate = new Date()
  const timezoneOffset = currentDate.getTimezoneOffset() * 60000
  const localDate = new Date(currentDate.getTime() - timezoneOffset)
  return localDate.toISOString().split('T')[0]
}

export function getYesterdayGameDate() {
  const currentDate = new Date()
  const timezoneOffset = currentDate.getTimezoneOffset() * 60000
  const localDate = new Date(currentDate.getTime() - timezoneOffset)
  localDate.setDate(localDate.getDate() - 1)
  return localDate.toISOString().split('T')[0]
}

export async function getGame(date: string): Promise<GameParams> {
  const res = await v1.get(`/riddle/${date}`)
  return res.data.data.riddle
}

export async function getNextGameTime(): Promise<Date> {
  const now = new Date()
  const nextGame = new Date(now)
  nextGame.setHours(0, 0, 0, 0)
  // setDate() accounts for month rollover
  nextGame.setDate(nextGame.getDate() + 1)
  return nextGame
}

export async function getAnswer(date: string): Promise<string> {
  const res = await v1.get(`/riddle/${date}/answer`)
  return res.data.data.answer
}

export async function getScore(date: string, guess: string): Promise<number> {
  const response = await v1.post(`/riddle/${date}/guess`, {
    guess: guess
      .trim()
      .toLowerCase()
      // Normalize apostrophes to single quotes
      .replace(/\u2019|\u0027/g, "'")
  })

  return response.data.data.score
}
