<template>
  <div class="timer">
    <div class="timer__countdown">
      <div class="timer__countdown-text">
        Next Riddlemoji in
        {{ timeToNextRiddle }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import { getNextGameTime } from '../lib/api'

let interval: number | null = null

const timeToNextRiddle = ref('')

onMounted(async () => {
  const nextRiddle = await getNextGameTime()

  interval = window.setInterval(() => {
    const currentDate = new Date()
    const timeLeft = nextRiddle.getTime() - currentDate.getTime()
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000)
    timeToNextRiddle.value = `${hours}h ${minutes}m ${seconds}s`

    if (hours === 0 && minutes === 0 && seconds < 2) {
      window.location.reload()
    }
  })
})

onUnmounted(() => {
  if (interval) {
    clearInterval(interval)
  }
})

</script>
