<template>
  <div class="stats w-full">
    <div class="stats__title font-semibold text-center w-full">
      Stats:
    </div>
    <div class="stats__items flex justify-center">
      <div class="py-3">
        <div
          class="stats__item">
          {{ bars.green }}
          <span class="font-semibold">
            {{ props.stats.green }}
          </span>
        </div>
        <div
          class="stats__item">
          {{ bars.yellow }}
          <span class="font-semibold">
            {{ props.stats.yellow }}
          </span>
        </div>
        <div
          class="stats__item">
          {{ bars.red }}
          <span class="font-semibold">
            {{ props.stats.red }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import GameStats from '../types/gameplay/GameStats'

import { getBars } from '../lib/gameplay'


const props = defineProps<{
  stats: GameStats
}>()

const bars = computed(() => {
  return getBars(props.stats)
})
</script>
