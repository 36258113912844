import * as Sentry from '@sentry/vue'

export function trackEvent(event: string, data: Record<string, any> = {}): void {
  if (import.meta.env.PROD) {
    if (window.umami) {
      window.umami.track(event, data)
    }
  } else {
    console.log('Event:', event, data || '')
  }
}

export function captureException(error: Error) {
  if (import.meta.env.PROD) {
    Sentry.captureException(error)
  } else {
    console.error(error)
  }
}

export function captureBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
  if (import.meta.env.PROD) {
    Sentry.addBreadcrumb(breadcrumb)
  } else {
    console.log('Breadcrumb:', breadcrumb)
  }
}