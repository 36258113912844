import Home from '../views/Home.vue'
import Play from '../views/Play.vue'

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/play',
    component: Play,
  },
  {
    path: '/junior',
    component: () => import('../views/Junior.vue'),
  },
]

export default routes
