import { captureException } from './lib/metrics'

const NAMESPACE = 'riddlemoji'

function removeState(key: string, exceptVersion: string) {
  if (key.startsWith(`${NAMESPACE}:onboarded:`)) {
    if (exceptVersion && key === `${NAMESPACE}:onboarded:${exceptVersion}`) {
      return
    }
    localStorage.removeItem(key)
  }
}

function setState(key: string, value: string, version: string) {
  removeState(key, version)
  localStorage.setItem(`${NAMESPACE}:${key}:${version}`, value)
}

export function getState(key: string, version: string): string | null {
  removeState(key, version)
  return localStorage.getItem(`${NAMESPACE}:${key}:${version}`)
}

export function getOnboardedState(version: string): boolean {
  try {
    const value = getState('onboarded', version)
    return value === 'true'
  } catch (err) {
    if (err instanceof Error) {
      captureException(err)
    }
    return true
  }
}

export function setOnboardedState(onboarded: boolean, version: string): void {
  try {
    setState('onboarded', onboarded.toString(), version)
  } catch (err) {
    if (err instanceof Error) {
      captureException(err)
    }
    throw err
  }
}
